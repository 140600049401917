import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  // {
  //   key: "register",
  //   path: `${AUTH_PREFIX_PATH}/register`,
  //   component: React.lazy(() =>
  //     import("views/auth-views/authentication/register")
  //   ),
  // },
  {
    key: "set-password",
    path: `${AUTH_PREFIX_PATH}/setPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/set-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgotPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },

  // {
  //   key: "set-password",
  //   path: `${APP_PREFIX_PATH}/setPassword`,
  //   component: React.lazy(() => import("../pages/SetPassword")),
  // },
];

export const protectedRoutes = [
  {
    key: "default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("pages/Users")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "clients",
    path: `${APP_PREFIX_PATH}/clients`,
    component: React.lazy(() => import("pages/Clients")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "clients-restaurant",
    path: `${APP_PREFIX_PATH}/clients-restaurant`,
    component: React.lazy(() => import("pages/ClientsRestaurant")),
    access: ["Restauracja"],
  },
  {
    key: "restaurants",
    path: `${APP_PREFIX_PATH}/restaurants`,
    component: React.lazy(() => import("pages/Restaurants")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "spendmeals",
    path: `${APP_PREFIX_PATH}/spendmeals`,
    component: React.lazy(() => import("pages/IssuedMealSearch")),
    access: ["Administrator"],
  },
  {
    key: "spendmeal-client",
    path: `${APP_PREFIX_PATH}/spendmeals/:clientCardNumber`,
    component: React.lazy(() => import("pages/SearchClientMeal")),
    access: ["Administrator"],
  },
  {
    key: "issuedmeals",
    path: `${APP_PREFIX_PATH}/issuedmeals`,
    component: React.lazy(() => import("pages/IssuedMeals")),
    access: ["Administrator"],
  },
  {
    key: "issuedmeals-restaurant",
    path: `${APP_PREFIX_PATH}/issuedmeals-restaurant`,
    component: React.lazy(() => import("pages/IssuedMealsRestaurant")),
    access: ["Restauracja"],
  },
  {
    key: "raports-restaurants",
    path: `${APP_PREFIX_PATH}/raports-restaurants`,
    component: React.lazy(() => import("pages/RaportsRestaurants")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "raports-restaurant",
    path: `${APP_PREFIX_PATH}/raports-restaurant`,
    component: React.lazy(() => import("pages/RaportsRestaurant")),
    access: ["Restauracja"],
  },
  {
    key: "raports-mentee",
    path: `${APP_PREFIX_PATH}/raports-mentee`,
    component: React.lazy(() => import("pages/RaportsMentee")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "user-details",
    path: `${APP_PREFIX_PATH}/users/:id`,
    component: React.lazy(() => import("../pages/UserDetailsPage")),
    access: ["Administrator"],
  },
  {
    key: "user-new",
    path: `${APP_PREFIX_PATH}/users/new`,
    component: React.lazy(() => import("../pages/UserEdit")),
    access: ["Administrator"],
  },
  {
    key: "user-edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("../pages/UserEdit")),
    access: ["Administrator"],
  },
  {
    key: "restaurant-new",
    path: `${APP_PREFIX_PATH}/restaurants/new`,
    component: React.lazy(() => import("../pages/RestaurantForm")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "restaurant-edit",
    path: `${APP_PREFIX_PATH}/restaurants/edit/:id`,
    component: React.lazy(() => import("../pages/RestaurantForm")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "restaurant-view",
    path: `${APP_PREFIX_PATH}/restaurants/view/:restaurantId`,
    component: React.lazy(() => import("../pages/RestaurantView")),
    access: ["Administrator", "Moderator"],
  },
  {
    key: "client-new",
    path: `${APP_PREFIX_PATH}/clients/new/`,
    component: React.lazy(() => import("../pages/ClientForm")),
    access: ["Administrator"],
  },
  {
    key: "client-view",
    path: `${APP_PREFIX_PATH}/clients/:clientID`,
    component: React.lazy(() => import("../pages/ClientProfileView")),
    access: ["Administrator"],
  },
  {
    key: "client-edit",
    path: `${APP_PREFIX_PATH}/clients/edit/:id`,
    component: React.lazy(() => import("../pages/ClientForm")),
    access: ["Administrator"],
  },
  {
    key: "card-view",
    path: `${APP_PREFIX_PATH}/clients/cards/:id`,
    component: React.lazy(() => import("../pages/ClientCard")),
    access: ["Administrator"],
  },
  {
    key: "dashboard-restaurant",
    path: `${APP_PREFIX_PATH}/dashboard-restaurant`,
    component: React.lazy(() => import("../pages/DashboardRestaurant")),
    access: ["Restauracja"],
  },
  {
    key: "client-groups",
    path: `${APP_PREFIX_PATH}/client-groups`,
    component: React.lazy(() => import("../pages/ClientGroups")),
    access: ["Administrator"],
  },
];

const URL = process.env.REACT_APP_ENDPOINT_URL
  ? process.env.REACT_APP_ENDPOINT_URL
  : "/api";

const dev = {
  API_ENDPOINT_URL: URL,
};

const prod = {
  API_ENDPOINT_URL: URL,
};

const test = {
  API_ENDPOINT_URL: URL,
};

const BACKEND_URL = "https://api.godnyobiad.pl";
const FRONTEND_URL = "https://godnyobiad.pl";

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};
export const env = getEnv();

export { BACKEND_URL };
export { FRONTEND_URL };
